<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->
    <!-- Start 404 Page  -->
    <div class="error-page-inner bg_color--4">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title" style="color:#fff;">404</h1>
              <h3 class="sub-title">페이지를 찾을 수 없습니다.</h3>
              <span>관리자에게 문의해주시기 바랍니다..</span>
              <div class="error-button">
                <router-link class="btn-default" to="/"
                  >홈으로</router-link
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End 404 Page  -->
    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        logo: require("../assets/images/logo/logo.png"),
      };
    },
  };
</script>
