<template>
  <footer class="footer-area footer-style-01 bg_color--4">
    <div class="footer-wrapper ptb--70">
      <v-container class="container">
        <v-row>
          <v-col lg="4" md="12" sm="12" cols="12">
            <div class="ft-text">
              <div class="logo">
                <a href="/"><img :src="logo" alt="brand image"/></a><br />
              </div>
              <p>
                (주)데이터엔지니어스랩 | 대표이사:안광종<br />
                서울특별시 서초구 방배로6길 13 배영빌딩 303호<br />
                Tel : 02-3453-8124 | e-mail : admin@dlab.kr
                </p>
                <p style="font-size:12px;clear:both;width:100%;line-height:130%;margin-top:0;"> ⓒ 2007 DATA ENGINEERS LAB CO., Ltd.
              </p>
            </div>
          </v-col>
          <v-col
            lg="2"
            offset-xl="1"
            md="6"
            sm="6"
            cols="6"
            class="mt_mobile--40"
          >
            <div class="footer-link">
              <h4>ABOUT</h4>
              <ul class="ft-link">
                <li><router-link to="/Overview">Overview</router-link></li>
                <li><router-link to="/Business">Business</router-link></li>
                <li><router-link to="/History">History</router-link></li>
                <li><router-link to="/Contact">Contact</router-link></li>
              </ul>
            </div>
          </v-col>
          <v-col lg="2" md="2" sm="6" cols="6" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>PRODUCT</h4>
              <ul class="ft-link">
                <li><router-link to="/DBAP">DBAP</router-link></li>
                <li><router-link to="/LoadUp">LoadUp</router-link></li>
                <li><router-link to="/Donto">Donto</router-link></li>
                <li><router-link to="/DStocks">DStocks</router-link></li>
                <li><router-link to="/DCrawler">DCrawler</router-link></li>
              </ul>
            </div>
          </v-col>
          <v-col lg="2" md="2" sm="6" cols="6" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>TECHNOLOGY</h4>
              <ul class="ft-link">
                <li><router-link to="/BigData">Big Data Platform</router-link></li>
                <li><router-link to="/SearchEngine">Search Engine</router-link></li>
                <li><router-link to="/Algorithm">Algorithm</router-link></li>
                <li><router-link to="/TextMining">Text Mining</router-link></li>
                <li><router-link to="/OcrAnalysis">OCR Analysis</router-link></li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        logo: require("../../assets/images/logo/logo-light.png"),
        socialList: [
          {
            icon: "fa-facebook-f",
            url: "https://www.facebook.com/",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://www.linkedin.com/",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/",
          },
          {
            icon: "fa-instagram",
            url: "https://www.instagram.com/",
          },
        ],
      };
    },
  };
</script>
